// components/TrackPage.js

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { initializeClarity, trackPageVisit } from '../../utils/msClarity';

const TrackClerityPage = () => {
  const user = useSelector((state) => state.user[0]); // Get current user
  const { details } = user;
  const location = useLocation();

  useEffect(() => {
    if (details?.uid === 1) {
      // Check if user and user.details exist before accessing them
      initializeClarity(); // Initialize Clarity on page load

      // Convert all inputs to string format
      const formattedUserEmail = String(details?.email);
      const formattedUserId = String(details?.id);
      const formattedUserName = String(details?.name);
      const formattedTherapist = String(details?.therapist?.position?.full_name);
      trackPageVisit({ formattedUserEmail, formattedUserId, formattedUserName, formattedTherapist }); // Track page visit for authenticated users
    }
  }, [location, user, details]);

  return null; // This component doesn't render anything
};

export default TrackClerityPage;
