// utils/clarityIntegration.js
import Clarity from '@microsoft/clarity';

export const initializeClarity = () => {
  Clarity.init(process.env.REACT_APP_CLERITY_ID);
};

export const trackPageVisit = ({ formattedUserEmail, formattedUserId, formattedUserName, formattedTherapist }) => {
  const formattedPagePath = window.location.pathname;

  // Identify the user with Clarity
  Clarity.identify(formattedUserName, {
    customSessionId: formattedUserId,
    customPageId: formattedPagePath,
    friendlyName: formattedUserName,
  });

  // Set tags for additional context
  if (formattedPagePath) {
    Clarity.setTag('Therapist Name', formattedUserName);
    Clarity.setTag('Therapist Position', formattedTherapist);
    Clarity.setTag('Therapist ID', formattedUserId);
    Clarity.setTag('Therapist Email', formattedUserEmail);
  }
};
