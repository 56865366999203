import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import path from '../path';
import Route from '../CustomRoute';

const session = React.lazy(() => import('../../screens/admin/ImportSession'));

export default function AdminRouter() {
  return (
    <React.Suspense
      fallback=<div>
        <div className="mg-b-20 d-flex justify-content-between flex-wrap tl-row-gap-10">
          <Skeleton width={200} height={20} />
          <Skeleton width={200} height={20} />
        </div>
        <Skeleton height="100vh" width="100%" />
      </div>
    >
      <Switch>
        <Route isPrivate path={`${path.admin.session}`} component={session} />
      </Switch>
    </React.Suspense>
  );
}
