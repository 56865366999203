import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FeatherIcon from 'feather-icons-react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Divider from './Divider';
import { getUserDetails, logoutUser } from '../../services/api/authService';
import { deleteUser, setUserDetails } from '../../store/userSlice';
import { authStorage } from '../../services/localStorage/authStorage';
import path from '../../routes/path';
import { setNotification } from '../../store/generalSlice';
import UserAvatar from './UserAvatar';
import { FEEDSPACE_REPORT_BUG_VIDEO_LINK, USER_TYPES } from '../../utils/constants/AppConstant';
import TLButton from './TL-Button';
import SkeletonAvatar from './SkeletonAvatar';
import TLModal from './TL-Modal';
import IconButton from '../student/common/IconButton';
import { getUserZohoInfo } from '../../services/api/therapist';
import { isDevelopment } from '../../utils/utils';
import { ReactComponent as BugSvg } from '../../assets/svgs/octicon_bug-24.svg';

const UserProfileDropdown = ({ history, setFetchingUserData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState();
  const [userZohoDetail, setUserZohoDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [therapistUser, setTherapistUser] = useState(false);

  let position = '';
  let userId = '';

  if (USER_TYPES.therapist === userData?.role.id) {
    position = userData?.therapist?.position.name;
  }

  if (USER_TYPES.therapist === userData?.role.id) {
    userId = userData?.therapist?.id;
  }

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        setFetchingUserData(true);
        const details = await getUserDetails();
        if (details?.status === 200) {
          const { data } = details.data;
          setUserData(data);
          dispatch(setUserDetails(data));
          setTherapistUser(data?.role?.id === 1);
        }
      } catch (err) {
        dispatch(
          setNotification({
            visible: true,
            title: err?.response?.data?.message || 'Something went wrong',
            type: 'error',
            delay: 4000,
          })
        );
        if (isDevelopment) {
          // eslint-disable-next-line no-console
          console.log('err ', err);
        }
      } finally {
        setLoading(false);
        setFetchingUserData(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       setLoading(true);
  //       const zohoDetails = await getUserInfo({
  //         id: user.details.therapist.id,
  //         params: ['include=user', `fields[therapists]=tid,uid`, `fields[user]=id,email,godaddypass`].join('&'),
  //       });
  //       if (zohoDetails?.status === 200) {
  //         const { data } = zohoDetails.data;
  //         setUserZohoDetail(data?.user);
  //       }
  //     } catch (err) {
  //       dispatch(
  //         setNotification({
  //           visible: true,
  //           title: err?.response?.data?.message || 'Something went wrong',
  //           type: 'error',
  //           delay: 4000,
  //         })
  //       );
  //     } finally {
  //       setLoading(false);
  //       setFetchingUserData(false);
  //     }
  //   })();
  // }, [dispatch]);

  const getUserZohoDetails = async () => {
    setLoading(true);
    try {
      const params = ['include=user', `fields[therapists]=tid,uid`, `fields[user]=id,email`];
      const res = await getUserZohoInfo({
        id: userId,
        params: params.join('&'),
      });
      if (res?.status === 200) {
        const { data } = res.data;
        setUserZohoDetail(data?.user);
      }
    } catch (err) {
      dispatch(
        setNotification({
          visible: true,
          title: err?.response?.data?.message || 'Something went wrong',
          type: 'error',
          delay: 4000,
        })
      );
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) getUserZohoDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  const logout = async () => {
    try {
      toast.loading('logging out..', {
        toastId: 'loading',
      });
      dispatch(deleteUser({ loggedIn: false }));
      const res = await logoutUser();

      if (res.status === 200) {
        history.push('/');
        dispatch(
          setNotification({
            visible: true,
            title: res?.data?.message || 'You have been successfully logged out!',
            type: 'success',
          })
        );
        authStorage.removeToken();
      }
    } catch (err) {
      dispatch(
        setNotification({
          visible: true,
          title: err?.response?.data?.message || 'Something went wrong',
          type: 'error',
          delay: 4000,
        })
      );
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', err);
      }
    } finally {
      toast.dismiss('loading');
    }
  };

  return loading ? (
    <div className="mg-l-15 mg-xl-l-30 mg-md-l-20">
      <SkeletonAvatar />
    </div>
  ) : (
    <>
      <TLModal
        show={showDetailsModal}
        // show
        onHide={() => {
          setShowDetailsModal(false);
        }}
        title="Email Login"
      >
        <div className="d-flex tl-gap-15 align-items-center">
          <div className="number">
            <div
              className="d-flex align-items-center bd bd-2 rounded-50 wd-30 ht-30 justify-content-center tx-medium tl-color-607396"
              style={{ lineHeight: '1' }}
            >
              1
            </div>
          </div>
          <div>
            <div className="d-inline-flex align-items-center">
              <Link
                className="tx-dark mg-r-5 underline_text tx-medium"
                to={{
                  pathname: `${userZohoDetail?.url}`,
                }}
                target="_blank"
              >
                Click Here
              </Link>
              <p className="mg-0">to go to Email Login Page</p>
            </div>
          </div>
        </div>
        <div className="d-flex tl-gap-15 align-items-center mg-t-15">
          <div className="number">
            <div
              className="d-flex align-items-center bd bd-2 rounded-50 wd-30 ht-30 justify-content-center tx-medium tl-color-607396"
              style={{ lineHeight: '1' }}
            >
              2
            </div>
          </div>
          <div>
            <div className="d-inline-flex flex-wrap flex-md-nowrap align-items-center">
              <span className="mg-r-5 tx-nowrap">Email:</span>

              <span className="d-inline-block mg-r-10">{userZohoDetail?.email}</span>

              <IconButton
                icon="copy"
                className="tx-dark"
                isPrimary={false}
                onClick={() => {
                  navigator.clipboard.writeText(userZohoDetail?.email);
                  dispatch(
                    setNotification({
                      visible: true,
                      title: 'Email copied',
                      type: 'success',
                      delay: 3000,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="d-flex tl-gap-15 align-items-center mg-t-15">
          <div className="number">
            <div
              className="d-flex align-items-center bd bd-2 rounded-50 wd-30 ht-30 justify-content-center tx-medium tl-color-607396"
              style={{ lineHeight: '1' }}
            >
              3
            </div>
          </div>
          <div>
            <div className="d-inline-flex flex-wrap flex-md-nowrap align-items-center">
              <span className="mg-r-5 tx-nowrap">Password:</span>
              <span className="tl-color-607396">
                {showPassword ? userZohoDetail?.email_password : <span className="pass-star">********</span>}
              </span>
              <div>
                <TLButton
                  label=""
                  className="tx-uppercase tx-20-f pd-y-0 pd-x-0 bd-0 rm-hover mg-l-10 showPW_btn"
                  size="xs"
                  prefixIcon={showPassword ? 'eye-off' : 'eye'}
                  variant="outline-dark"
                  onClick={() => setShowPassword(!showPassword)}
                />

                <IconButton
                  icon="copy"
                  className="tx-dark mg-l-10"
                  isPrimary={false}
                  onClick={() => {
                    navigator.clipboard.writeText(userZohoDetail?.email_password);
                    dispatch(
                      setNotification({
                        visible: true,
                        title: 'Password copied',
                        type: 'success',
                        delay: 3000,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="tl-bg-EEEFF4 mg-t-20 tx-12 pd-y-8 pd-x-10 rounded">
          <span className="tl-color-3F4654 tx-medium">Note:</span> Please do not change your email password. If you
          change the password you will not be able to send/receive email. Also, please skip the option to set up
          2-factor authentication as this will disrupt the direct connection to your email.
        </div> */}
      </TLModal>
      <Dropdown className="dropdown-profile" show={open} onToggle={(isOpen) => setOpen(isOpen)}>
        <Dropdown.Toggle
          as="a"
          disabled={loading}
          className="nav-link bd-0 dropdown-link pd-0 no-arrow rounded-circle tl-cursor-pointer"
        >
          <UserAvatar initials={userData?.initials} />
        </Dropdown.Toggle>
        <Dropdown.Menu as="div" className="dropdown-menu-end">
          <div className="d-flex tl-col-gap-10">
            <UserAvatar initials={userData?.initials} height={42} width={42} />
            <div className="d-flex align-items-start flex-column justify-content-center">
              <h6 className="mg-0 tx-semibold tx-14">{userData?.name}</h6>
              {position && <p className="mg-0 mg-t-5 tx-12 tl-color-8392A5">{position}</p>}
            </div>
          </div>

          {therapistUser && (
            <TLButton
              label="E-Therapy Email"
              className="tx-uppercase wd-100p mg-t-15"
              variant="outline-primary"
              onClick={() => {
                setShowDetailsModal(true);
                setOpen(false);
              }}
            />
          )}

          <Divider className="mg-t-20 mg-b-15" />
          <div>
            {therapistUser && (
              <>
                <Link to={path.therapist.profile} className="dropdown-item" onClick={() => setOpen(false)}>
                  <FeatherIcon icon="user" className="wd-15" />
                  My Profile
                </Link>
                <Link to={path.therapist.changePassword} className="dropdown-item" onClick={() => setOpen(false)}>
                  <FeatherIcon icon="lock" className="wd-15" />
                  Change Password
                </Link>
                <Link to={path.therapist.faq} className="dropdown-item" onClick={() => setOpen(false)}>
                  <FeatherIcon icon="help-circle" className="wd-15" />
                  FAQs
                </Link>
              </>
            )}

            <a href={FEEDSPACE_REPORT_BUG_VIDEO_LINK} className="dropdown-item" target="_blank" rel="noreferrer">
              <BugSvg className="wd-15" />
              Report A Bug
            </a>
          </div>
          <Divider />
          <Link onClick={logout} to="#" className="dropdown-item">
            <FeatherIcon icon="log-out" className="wd-15" />
            Logout
          </Link>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default withRouter(UserProfileDropdown);
