import React from 'react';
import Layout from '../../layouts/Layout';
import AdminRouter from '../../routes/admin';

const Admin = () => (
  <>
    <Layout>
      <AdminRouter />
    </Layout>
  </>
);

export default Admin;
